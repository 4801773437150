import 'scroll-behavior-polyfill';
import {} from 'jquery-ujs';
import './services/alert';
import './services/dropdown';
import './services/modal';
import './services/modal_content';

$(document).ready(() => {
  $(document).trigger('dom-update');
});
