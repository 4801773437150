const SELECTOR = '.alert:not(.disclaimer)';

$(document).on('dom-update', (e) => {
  $(e.target)
    .find(`${SELECTOR}:not(.no-fading)`)
    .delay(6000)
    .fadeOut();
});

$(document).on('click', `${SELECTOR} .close`, (e) => {
  $(e.target)
    .closest(SELECTOR)
    .clearQueue()
    .fadeOut();
});
