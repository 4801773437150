const modals = [];

async function loadComponent() {
  const { default: Modal } = await import(/* webpackChunkName: "modal" */ '@bizneo/modal/modal.svelte');

  return Modal;
}

export function open(content, body) {
  loadComponent().then((Modal) => {
    const modal = new Modal({
      target: body,
      props: { content },
    });

    modal.$on('close', () => {
      modal.$destroy();
      modals.pop();
    });

    modals.push(modal);
    $(modal.getContainer()).trigger('dom-update', { source: 'modal' });
  });
}

export function replace(content) {
  const modal = modals[modals.length - 1];

  if (modal) {
    modal.replace(content);
    setTimeout(() => {
      $(modal.getContainer()).trigger('dom-update', { source: 'modal' });
    }, 0);
  } else {
    open(content);
  }
}

export const close = () => modals[modals.length - 1].close();

window.close_modal = close;

$(document).ready(() => {
  if ($('#cv-sent-modal').length) {
    $(document).keydown((e) => {
      if (e.keyCode === 27 && $('.modal.active .cv-sent-modal-with-form').length) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  $('#cv-sent-modal').each((_, el) => {
    const $el = $(el);
    loadComponent().then((Modal) => {
      const modal = new Modal({
        target: $el.parents('body')[0],
        props: { content: $el.html() },
      });
      if ($(modal.getContainer()).find('.cv-sent-modal-with-form').length) {
        $(modal.getContainer()).addClass('prevent-close');
      }
      modal.$on('close', () => modal.$destroy());
    });
  });
});
