import { open } from './modal';

const SELECTOR = '[data-modal-content]';

$(document).on('click', `${SELECTOR}`, (e) => {
  const $el = $(e.target).data().content === undefined ? $(e.target).closest('[data-modal-content]') : $(e.target);
  const { target } = $el.data();
  const body = $el.parents('body')[0];

  setTimeout(() => {
    open($(target).html(), body);
  }, 500);
}).on('click', '#modal-password-link', () => {
  $('.modal.active form').replaceWith($('#password-modal').html());
});
