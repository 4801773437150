const SELECTOR = '.dropdown';

async function loadComponent() {
  const { default: mountDropdown } = await import(/* webpackChunkName: "dropdown" */ '@bizneo/dropdown/dropdown');

  return mountDropdown;
}

$(document).on('dom-update', (e) => {
  $(e.target)
    .find(SELECTOR)
    .each((_, element) => loadComponent().then((mountDropdown) => mountDropdown(element)));
});
